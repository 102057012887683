<template>
 <div  class="latest-articles">
   <v-container class="py-0">
    <v-row>
      <v-col cols="12">
        <h2 class="section-title">أحدث المقالات والأخبار</h2>
      </v-col>
    </v-row>
    <v-row class="articles">
      <v-col cols="12" md="4" v-for="(article, index) in articles" :key="index">
        <v-card class="article-card">
          <img class="article-img" :src="article.image" />
          <v-card-text>
            <h3 class="article-title">{{ article.title }}</h3>
            <p class="article-description">{{ article.description }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn class="read-more-btn" text>
              اقرأ المزيد
              <v-icon right>mdi-chevron-left</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
 </div>
</template>

<script>
export default {
  data() {
    return {
      articles: [
        {
          image: require("../../assets/images/landing-page/FAQS1.png"),
          title: "عنوان البوست هنا",
          description:
            "لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل.",
        },
        {
          image: require("../../assets/images/landing-page/FAQS2.png"),
          title: "عنوان البوست هنا",
          description:
            "لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل.",
        },
        {
          image: require("../../assets/images/landing-page/FAQS3.png"),
          title: "عنوان البوست هنا",
          description:
            "لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل.",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.latest-articles {
  margin-top: 4rem;
  margin-bottom: 2.25rem;
  .section-title {
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #000000;
  }

  .articles {
    .article-card {
      box-shadow: unset !important;
      border-radius: 10px;
      overflow: hidden;
      .article-img {
        width: 100%;
        height: 100%;
      }
      .article-title {
        font-size: 1.25rem;
        font-weight: 700;
        margin-top: 2.029rem;
        margin-bottom: 1rem;
        line-height: 1.395rem;
        color: #000000;

      }

      .article-description {
        font-size: 1.063rem;
        color: #4E4E4E;
        line-height: 1.186rem;
        font-weight: 400;
        margin-bottom: 0.117rem;
      }

      .read-more-btn {
        color: #C53C1C;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.116rem;
        padding: 0px;
        margin-top: 1.617rem;
        .v-btn__content {
        i {
          margin-inline-end: 0.94rem;
        }
        }
        
      }
    }
  }
}
.theme--light.v-card>.v-card__subtitle, .theme--light.v-card>.v-card__text {
  padding: 0px !important;
}
.v-card__actions {
  padding: 0px !important;
}
</style>
