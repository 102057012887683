<template>
  <div class="nav-bar">
    <v-container class="py-6">
      <v-toolbar dense flat>
        <div
          v-if="!isLoggedIn"
          class="navbar d-flex align-center h-100 w-100 mx-auto"
        >
          <router-link class="navbar__logo ml-md-9" :to="{ name: 'home' }">
            <img width="90px" src="@/assets/images/logo-blue.svg" />
          </router-link>
          <a
            href="#digital-solutions"
            class="navbar__link d-flex align-center h-100 black--text text-decoration-none ml-9"
            :class="{ active: activeSection === 'digital-solutions' }"
          >
            الحلول الرقمية
          </a>
          <a
            href="#services"
            class="navbar__link d-flex align-center h-100 black--text text-decoration-none ml-9"
            :class="{ active: activeSection === 'services' }"
          >
            الخدمات
          </a>
          <a
            href="#faq"
            class="navbar__link d-flex align-center h-100 black--text text-decoration-none ml-9"
            :class="{ active: activeSection === 'faq' }"
          >
            الاسئلة الشائعة
          </a>
          <a
            href="#blog"
            class="navbar__link d-flex align-center h-100 black--text text-decoration-none ml-9"
            :class="{ active: activeSection === 'blog' }"
          >
            المدونه
          </a>
          <a
            href="#contact"
            class="navbar__link d-flex align-center h-100 black--text text-decoration-none ml-9"
            :class="{ active: activeSection === 'blog' }"
          >
          تواصل معنا
          </a>
          <v-spacer></v-spacer>
          <router-link
            exact
            :to="{ name: 'register' }"
            class="navbar__link d-flex align-center h-100 black--text text-decoration-none ml-9"
          >
            انضم الينـا
          </router-link>
          <v-btn
            @click="$router.push({ name: 'login' })"
            dark
            color="#c53c1c"
            class="rounded-lg elevation-0"
          >
          <span class="text-base">  تسجيل الدخول</span>
          </v-btn>
        </div>
        <div
          v-if="isLoggedIn"
          class="navbar d-flex align-center justify-between w-100 mx-auto register-nav"
        >
          <div class="d-flex align-center gap-22">
            <div class="navbar__logo">
              <img width="90px" src="@/assets/images/logo-blue.svg" />
            </div>

            <img src="@/assets/images/vertical-seperator.svg" />
            <div class="d-flex align-center gap-12">
              <img
                width="22px"
                class="mb-1"
                src="@/assets/images/goodbye.svg"
              />
              <span class="text-sm font-bold"
                >أهلاً {{ $store.getters.userInfo.fullName }}</span
              >
            </div>
            <img width="1px" src="@/assets/images/vertical-seperator.svg" />
            <div class="d-flex align-center gap-14 cursor-pointer">
              <img src="@/assets/images/search.svg" />
              <span class="lightgray--text font-normal text-xs"
                >ابحـث في كاشيـب</span
              >
            </div>
          </div>
          <div class="d-flex align-center gap-30">
            <div class="d-flex align-center gap-8 cursor-pointer">
              <img src="@/assets/images/globe.svg" />
              <span class="black--text font-normal text-xs">ع</span>
            </div>
            <v-badge
              class="cursor-pointer"
              :content="1"
              :value="1"
              color="error"
              overlap
            >
              <img src="@/assets/images/bell.svg" />
            </v-badge>
            <img class="rounded-lg" src="@/assets/images/profile-picture.svg" />
          </div>
        </div>
      </v-toolbar>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeSection: "",
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  mounted() {
    const sections = ["digital-solutions", "services", "faq", "blog"];
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.activeSection = entry.target.id;
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    sections.forEach((section) => {
      const sectionElement = document.querySelector(`#${section}`);
      if (sectionElement) {
        observer.observe(sectionElement);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  &__link {
    font-size: 1rem;
    font-weight: 400;
    position: relative;

    &:not(:last-child) {
      margin-left: 9px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: #c53c1c;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover,
    &.active {
      color: $primary;
      &::after {
        transform: scaleX(1);
        transform-origin: bottom right;
      }
      .navbar__account__icon {
        color: inherit !important;
        transform: rotateX(180deg);
      }
    }
    &.router-link-active {
      color: #c53c1c !important;
      &::after {
        transform: scaleX(1);
      }
    }
  }
}
.nav-bar {
  background-color: #f1f1f1;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  ::v-deep .v-toolbar.v-sheet,
  ::v-deep .v-toolbar__content {
    height: unset !important;
  }
}
::v-deep .v-toolbar__content,
.v-toolbar__extension {
  padding: 0px;
}
::v-deep .v-toolbar.v-sheet {
  background-color: #f1f1f1 !important;
}
.register-nav {
  padding: 16px;
}
</style>
