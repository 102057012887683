<template>
  <v-container class="blog-section py-0">
    <div class="blog-section__content">
      <p class="blog-section__content--header">مستعد لتبدأ مع كاشيب؟</p>
      <h1 class="blog-section__content--title">تحدث الى الخبير</h1>
      <p class="blog-section__content--text">
        هل لديك أي استفسار أو تحتاج إلى دعم؟ خبراؤنا جاهزون للإجابة ومساعدتك في
        كل خطوة.
      </p>
      <div class="blog-section__content--btn">
        <v-btn @click="$parent.openContactExpertDialog()" depressed large class="rounded-lg">
          تواصــل مع الخبيــر
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.blog-section {
  &__content {
    background: linear-gradient(180deg, #1b335e 0%, #386ac4 100%);
    border-radius: 1rem;
    min-height: 20.688rem;
    color: #ffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &--header {
      margin-bottom: 0.25rem;
    }
    &--title {
      font-size: 2.5rem;
      margin-bottom: 0.5rem;
    }
    &--btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.625rem;
      button {
        background-color: #ffff !important;
        color: #1b335e !important;
        font-weight: 700;
      }
    }
  }
}
</style>
>
