<template>
  <div class="form-page register-page d-flex">
    <div class="form-page__form pe-15">
      <v-dialog
        v-model="registerDialog"
        width="375"
        overlay-color="#D9D9D9"
        overlay-opacity="0.4"
      >
        <v-card class="popup pa-6 text-center">
          <p class="popup__label error--text">تم تسجيلك بنجاح.</p>
          <p class="popup__text">
            شكرًا لتسجيلك في كاشيب! سيتم التواصل معك قريبًا لتفعيل حسابك.
          </p>
        </v-card>
      </v-dialog>
      <alert-component
        :alertType="alertType"
        :showAlert="showAlert"
        :alertText="alertText"
      ></alert-component>
      <FormHeaderComponent
        :formHeaderBtn="{ show: formHeaderBtnShow, text: formHeaderBtnText }"
        :formHeaderText="formHeaderText"
        :formHeaderDescription="formHeaderDescription"
      />
      <v-tabs v-if="isServiceProvider" v-model="tab" optional class="mt-7">
        <v-tab style="width: 50%" v-for="item in tabItems" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-form
        v-if="registerForm"
        class="rounded mt-5"
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col class="col-6 py-0 px-2">
            <v-subheader class="black--text px-0">الاسم الأول</v-subheader>
            <v-text-field
              :rules="[rules.required, rules.name]"
              v-model="model.firstName"
              dense
              outlined
            >
              ></v-text-field
            >
          </v-col>
          <v-col class="col-6 py-0 px-2">
            <v-subheader class="black--text px-0">الاسم الأخير</v-subheader>
            <v-text-field
              :rules="[rules.required, rules.name]"
              v-model="model.lastName"
              dense
              outlined
            >
              ></v-text-field
            >
          </v-col>
          <v-col class="col-12 py-0 px-2">
            <v-subheader class="black--text px-0">رقم الهاتف</v-subheader>
            <div class="mobile-phone-field">
              <div class="mobile-phone-field__details">
                <img src="@/assets/images/ksa-flag.svg" alt="KSA Flag" />
                <span dir="ltr" class="mt-1.5"> +966 </span>
                <img
                  src="@/assets/images/vertical-seperator.svg"
                  alt="KSA Flag"
                />
              </div>
              <v-text-field
                maxlength="9"
                :rules="[rules.required, rules.numbers, rules.saudiNumber]"
                v-model="model.mobile"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col class="col-12 py-0 px-2">
            <v-subheader class="black--text px-0">
              البريد الإلكتروني
            </v-subheader>
            <v-text-field
              :rules="[rules.required, rules.email]"
              v-model="model.email"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="col-12 py-0 px-2">
            <v-subheader class="black--text px-0">أسـم الشركـة</v-subheader>
            <v-text-field
              v-model="model.companyName"
              :rules="[rules.name, rules.required]"
              dense
              outlined
            >
              ></v-text-field
            >
          </v-col>
          <v-col v-if="!isServiceProvider" class="col-12 py-0 px-2">
            <v-subheader class="black--text px-0">كلمة المرور </v-subheader>
            <v-text-field
              v-model="model.password"
              :rules="[rules.required, rules.password]"
              dense
              outlined
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @input="checkPasswordStrength"
              @click:append="showPassword = !showPassword"
            ></v-text-field>

            <!-- Password Rules List -->
            <div
              v-if="model.password"
              class="pe-0 ps-2 d-flex align-center flex-wrap gap-10 password-rules-list"
            >
              <v-chip
                v-for="(rule, index) in passwordRules"
                :key="index"
                small
                :color="rule.passed ? 'success' : 'error'"
                outlined
              >
                <v-icon small class="me-1">
                  {{ rule.passed ? "mdi-check-circle" : "mdi-close-circle" }}
                </v-icon>
                <span class="text-xs"> {{ rule.message }}</span>
              </v-chip>
            </div>
          </v-col>
        </v-row>
        <v-card-actions class="mt-10 pa-0 flex-wrap">
          <v-btn
            @click="register()"
            dark
            large
            color="error"
            class="w-100 rounded-lg"
          >
            <span class="font-bold">تسجيـل حسـاب جديـد</span></v-btn
          >
          <div
            class="align-items-center justify-center d-flex w-100 gap-1 mt-5"
          >
            <span class="gray--text text-sm font-bold"> تملك حساب؟ </span>
            <v-btn
              @click="$router.push({ name: 'login' })"
              text
              color="black"
              class="form-page__form__new-account h-unset pa-0"
              ><span class="text-sm font-bold"> تسجيل الدخـول</span></v-btn
            >
          </div>
        </v-card-actions>
      </v-form>
    </div>
    <FormDescriptionComponent />
    <loader-component :showLoader="showLoader"></loader-component>
  </div>
</template>

<script>
import FormHeaderComponent from "@/common/components/FormHeaderComponent.vue";
import FormDescriptionComponent from "@/common/components/FormDescriptionComponent.vue";
import LoaderComponent from "@/common/components/LoaderComponent.vue";
import visibility from "@/common/directives/visibility";
import authentication from "@/common/services/authentication";
import AlertComponent from "@/common/components/AlertComponent.vue";

export default {
  name: "RegisterView",
  props: {},
  mixins: [authentication],
  components: {
    LoaderComponent,
    FormHeaderComponent,
    FormDescriptionComponent,
    AlertComponent,
  },
  data() {
    return {
      registerDialog: false,
      alertType: "",
      alertText: "",
      showAlert: false,
      tab: null,
      tabItems: ["مخلص جمركـى", "ناقــل"],
      showPassword: false,
      showLoader: false,
      model: {
        password: "",
      },
      valid: true,
      passwordRules: [
        {
          message: "حرف كبير",
          passed: false,
          pattern: /[A-Z]/,
        },
        {
          message: "حرف صغير",
          passed: false,
          pattern: /[a-z]/,
        },
        {
          message: "رقم (0-9) ",
          passed: false,
          pattern: /\d/,
        },
        {
          message: "8 أحرف فأكثر",
          passed: false,
          pattern: /.{8,}/,
        },
      ],
      rules: {
        required: (value) =>
          !!value || [true, false].includes(value) || "هذا الحقل إلزامي",
        email: (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "البريد الالكتروني  غير صحيح",
        name: (value) =>
          /^[A-Za-z\u0600-\u06FF\s]+$/.test(value) ||
          "يجب أن يحتوي الاسم على أحرف أبجدية فقط",
        numbers: (value) =>
          !value ||
          (value && /^\d+$/.test(value)) ||
          "يجب أن يحتوي علي أرقام فقط",
        saudiNumber: (value) =>
          !value ||
          (value && /^5\d{8}$/.test(value)) ||
          "يجب أن يكون رقم الجوال سعودياً ويبدأ بـ 5 ويحتوي على 9 أرقام",
        password: (value) =>
          /^[a-zA-Z0-9]*$/.test(value) ||
          "يجب أن يحتوي علي أحرف إنجليزية و أرقام فقط",
      },
      formHeaderBtnShow: false,
      formHeaderBtnText: "",
      formHeaderText: "حسـاب جديـد",
      formHeaderDescription: "",
      isServiceProvider: false,
      userType: "",
      registerForm: true,
    };
  },
  directives: {
    visibility,
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    checkPasswordStrength() {
      this.passwordRules.forEach((rule) => {
        rule.passed = rule.pattern.test(this.model.password);
      });
    },
    async register() {
      await this.validate();
      if (this.valid) {
        if (!this.isServiceProvider) {
          const allRulesPassed = this.passwordRules.every(
            (rule) => rule.passed
          );
          if (!allRulesPassed) {
            // alert("Please ensure all password rules are met.");
            return;
          }
        }
        try {
          this.showLoader = true;
          let response = await this.createUser(this.model);
          if (response.isSuccess) {
            // this.showAlert = false;
            // let userInfo = response.data;
            // localStorage.setItem("userInfo", JSON.stringify(userInfo));
            // if (!this.isServiceProvider) {
            //   this.$router.push({
            //     name: "otp",
            //     query: { otp_type: "register" },
            //   });
            // } else {
            //   this.$router.push({
            //     name: "form-succeed",
            //     query: { form_succeed_type: "provider" },
            //   });
            // }
            this.registerDialog = true;
          } else {
            this.alertType = "error";
            this.alertText = response.error.message;
            this.showLoader = false;
            this.showAlert = true;
          }
          this.showLoader = false;
        } catch (error) {
          this.alertType = "error";
          this.alertText = "خطأ في التسجيل, الرجاء المحاولة لاحقا";
          this.showLoader = false;
          this.showAlert = true;
        }
      }
    },
  },
  watch: {
    tab: {
      handler(nv) {
        nv === null && this.$route.query["service_provider"]
          ? (this.registerForm = false)
          : (this.registerForm = true);
        if (this.tab == 0) {
          this.model.userType = 3;
        } else if (nv && this.tab == 1) {
          this.model.userType = 2;
        }
      },
      immediate: true,
    },
    "$route.query": {
      handler(nv, ov) {
        if (nv["service_provider"]) {
          this.isServiceProvider = true;
          this.formHeaderDescription =
            "أدخل البيانات المطلوبة للانضمام إلى شبكتنا اللوجستية";
        } else {
          this.isServiceProvider = false;
          this.formHeaderDescription =
            "أدخل البيانات المطلوبة لإنشاء حساب في منصة كاشيب";
        }
      },
      deep: true,
      immediate: true, // Optional, if you want to watch nested changes
    },
  },
};
</script>
  
 <style lang="scss">
.mobile-phone-field {
  position: relative;
  direction: ltr;
  &__details {
    position: absolute;
    bottom: 36px;
    left: 12px;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: #fbfbfb;
  }
  .v-input__slot {
    padding-inline-start: 150px !important;
  }
  .v-text-field__slot {
    input {
      margin-top: 6px;
    }
  }
}
</style>