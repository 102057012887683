<template>
  <div class="steps-section">
    <v-container>
    <v-row justify="space-between" align="center" class="steps-section__header">
      <v-col cols="12" md="6">
        <h3 class="steps-section__header--header">
          خطوات بسيطة لإدارة وتتبع  شحناتك
        </h3>
        <p class="steps-section__header--text">
          مع كاشيب، كل شيء مرتب وسهل. ما عليك إلا تتبع خطواتنا البسيطة وتخلي الباقي علينا
        </p>
      </v-col>
        <v-col cols="12" md="5">
        <img class="steps-section__header--img" src="../../assets/images/landing-page/steps-bg.png" />
      </v-col>
    </v-row>
    <v-row class="step__content" justify="space-between" align="center">
      <v-col cols="12 px-6" md="4" v-for="(step, index) in steps" :key="index">
        <div class="step">
          <div class="step-number">
            <img :src="step.icon" />
          </div>
          <h4 class="step-title">
            {{ step.title }}
          </h4>
          <p class="step-description">
            {{ step.description }}
          </p>
        </div>
      </v-col>
    </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      steps: [
        {
          icon: require("../../assets/images/landing-page/step1.svg"),
          title: "انشئ حسابك",
          description:
            "سجل الآن في كاشيب وتواصل مع خبرائنا للحصول على الدعم الذي تحتاجه أو الإجابة عن استفساراتك.",
        },
        {
          icon: require("../../assets/images/landing-page/step2.svg"),
          title: "أدخل تفاصيل شحنتك",
          description:
            "أدخل تفاصيل الشحنة بسهولة ودع  كاشيب  تتعامل مع الباقي.",
        },
        {
          icon: require("../../assets/images/landing-page/step3.svg"),
          title: "ابق على اطلاع دائم بحالة شحناتك",
          description:
            "راقب شحناتك مباشرة عبر لوحة تحكم بسيطة، واحصل على تحديثات دقيقة ولحظية لكل مراحل التي تمر بها الشحنة",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.steps-section {
  background-color: #FFFFFF;
  &__header{
    margin-bottom: 2.5rem;
      &--img {
  }
  &--header {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 3.125rem;
    margin-bottom: 1.375rem;
    color: #3064C1;
    background: #1B335E0D;
    border-radius: 0.75rem;
    padding: 0.75rem;
  }
  &--text{
    font-size: 1.5rem;
    color: #0F172A;
    font-weight: 400;
    margin-bottom: 0rem;
  }

  }

  .step {
    position: relative;
    border-top: 0.063rem solid #8da9cd;
    padding-top: 3.125rem;
    min-height: 12.5rem;
    &__content {

    }
    .step-border {
      content: "";
      display: block;
      width: 100%;
      height: 0.063rem;
      background-color: #ccc; /* Light gray for the border */
      margin-bottom: 1rem;
      margin-inline-start: 1.5rem;
    }

    .step-number {
      position: absolute;
      top: -1.625rem;
      right: 0;
      width: 4.5rem;
      background-color: #FFFFFF;
      img {
        width: 3rem;
        height: 3rem;
      }
    }

    .step-title {
      font-size: 1.25rem;
      font-weight: 700;
      margin-bottom: 1.125rem;
      color: #0F172A;
    }

    .step-description {
      font-size: 1rem;
      color: #666;
      margin-bottom: 0rem;
    }
  }
}
</style>
