<template>
  <div class="home">
    <HeroSection ref="heroRef" />
    <FeaturesSection />
    <InnovativeSection id="digital-solutions" />
    <ServicesSection id="services" />
    <StepsSection />
    <FaqSection id="faq" />
    <ContactExpertSection ref="contactExpertRef" id="contact" />
    <BlogSection id="blog" />
  </div>
</template>

<script>
import BlogSection from "@/components/homeComponents/BlogSection.vue";
import FeaturesSection from "@/components/homeComponents/FeaturesSection.vue";
import StepsSection from "@/components/homeComponents/StepsSection.vue";
import InnovativeSection from "@/components/homeComponents/InnovativeSection.vue";
import ServicesSection from "@/components/homeComponents/ServicesSection.vue";
import ContactExpertSection from "@/components/homeComponents/ContactExpertSection.vue";
import FaqSection from "@/components/homeComponents/FaqSection.vue";
import HeroSection from "@/components/homeComponents/Herosection.vue";

export default {
  components: {
    FeaturesSection,
    BlogSection,
    StepsSection,
    FaqSection,
    InnovativeSection,
    ServicesSection,
    HeroSection,
    ContactExpertSection,
  },
  data: () => ({
    panel: [0],
    panels: [
      {
        id: 1,
        title: "ما هي الخدمات التي تقدمونها في مجال الشحن الدولي؟",
        content:
          "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
      },
      {
        id: 2,
        title: "كيف يمكنني تتبع شحنتي؟",
        content:
          "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
      },
      {
        id: 3,
        title: "ما هي المستندات المطلوبة لتخليص الشحنات من الجمارك؟",
        content:
          "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
      },
      {
        id: 4,
        title: "هل تقدمون خدمات التخزين؟",
        content:
          "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
      },
      {
        id: 5,
        title: "كيف يمكنني الحصول على عرض سعر لشحنتي؟",
        content:
          "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
      },
      {
        id: 6,
        title: "ما هي الدول التي تقومون بالشحن إليها؟",
        content:
          "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
      },
    ],
    servicesTypes: [
      {
        id: 1,
        img: "@/assets/images/landing-page/online-shipping.svg",
        title: "شحن اونلاين",
        content:
          "لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ استخدامه في القرن الخامس عشر عندما قامت مطبعة غير",
      },
      {
        id: 2,
        img: "@/assets/images/landing-page/online-shipping.svg",
        title: "شحن اونلاين",
        content:
          "لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ استخدامه في القرن الخامس عشر عندما قامت مطبعة غير",
      },
      {
        id: 3,
        img: "@/assets/images/landing-page/online-shipping.svg",
        title: "شحن اونلاين",
        content:
          "لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ استخدامه في القرن الخامس عشر عندما قامت مطبعة غير",
      },
      {
        id: 4,
        img: "@/assets/images/landing-page/online-shipping.svg",
        title: "شحن اونلاين",
        content:
          "لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ استخدامه في القرن الخامس عشر عندما قامت مطبعة غير",
      },
      {
        id: 5,
        img: "@/assets/images/landing-page/online-shipping.svg",
        title: "شحن اونلاين",
        content:
          "لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ استخدامه في القرن الخامس عشر عندما قامت مطبعة غير",
      },
      {
        id: 6,
        img: "@/assets/images/landing-page/online-shipping.svg",
        title: "شحن اونلاين",
        content:
          "لوريم إيبسوم هو نص تجريبي يستخدم في صناعة الطباعة والتنضيد. بدأ استخدامه في القرن الخامس عشر عندما قامت مطبعة غير",
      },
    ],
  }),
  methods: {
    openContactExpertDialog() {
      this.$refs.heroRef.openContactExpertDialog();
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  width: 100%;
}
</style>