<template>
  <div class="privacy">
    <div class="privacy__header">
      <v-container class="pa-0"
        ><p class="privacy__header__title">
          سياسة الخصوصية لمنصة كاشب
        </p></v-container
      >
    </div>
    <v-container class="privacy__body">
      <p class="font-extrabold my-16">آخر تحديث: [20/11/2024]</p>

      <div class="my-16">
        <p class="font-semibold">مقدمة</p>
        <p class="">
          نشكركم على ثقتكم بمنصة كاشيب. نحن ملتزمون بحماية خصوصيتكم وضمان أمان
          معلوماتكم الشخصية. تهدف هذه السياسة إلى توضيح كيفية جمع واستخدام
          ومشاركة وحماية معلوماتكم عند استخدامكم لمنصتنا.
        </p>
      </div>
      <div class="my-16">
        <p class="font-semibold">المعلومات التي نقوم بجمعها</p>
        <p class="">
          نلتزم بجمع البيانات الضرورية فقط لتوفير الخدمات المطلوبة منكم وبما
          يتماشى مع القوانين واللوائح المحلية ذات الصلة.
        </p>
      </div>
      <div class="my-16">
        <p class="font-semibold">1. المعلومات التي تزودنا بها:</p>
        <p class="">
          · الاسم، البريد الإلكتروني، رقم الهاتف، وغيرها من المعلومات الشخصية
          عند التسجيل.
        </p>
        <p class="">
          · تفاصيل الشحن، مثل العناوين، معلومات الفواتير، ومواصفات الشحنات.
        </p>
      </div>
      <div class="my-16">
        <p class="font-semibold">2. المعلومات التي نجمعها تلقائيًا:</p>
        <p class="">
          · بيانات الاستخدام، مثل الصفحات التي تزورها وطريقة تفاعلك مع المنصة.
        </p>
        <p class="">
          · معلومات الجهاز، مثل نوع الجهاز، نظام التشغيل، وعنوان بروتوكول
          الإنترنت (IP).
        </p>
      </div>
      <div class="my-16">
        <p class="font-semibold">كيفية استخدام المعلومات</p>
        <p class="">
          نستخدم المعلومات التي نجمعها للأغراض التالية، مع الالتزام بمبادئ
          الخصوصية والشفافية المنصوص عليها في القوانين السعودية:
        </p>
        <p class="">
          <span class="font-semibold"> · تقديم الخدمات:  </span>
          توفير خدمات الشحن وإدارتها، بما في ذلك الحجز، التتبع، والتخليص الجمركي
        </p>
        <p class="">
          <span class="font-semibold"> · تحسين التجربة:</span>
          تطوير أداء المنصة وتعزيز تجربة المستخدم.
        </p>
        <p class="">
          <span class="font-semibold">· التواصل: </span>
           إرسال إشعارات حول الشحنات أو تحديثات على الخدمات.
        </p>
        <p class="">
          <span class="font-semibold">· الامتثال: </span>
          الامتثال للمتطلبات القانونية والتنظيمية.
        </p>
      </div>
      <div class="my-16">
        <p class="font-semibold">مشاركة المعلومات</p>
        <p class="">
          لا نقوم ببيع أو تأجير معلوماتكم الشخصية لأي طرف ثالث. قد نشارك
          المعلومات فقط في حالة الامتثال للمتطلبات القانونية أو بناءً على طلب
          السلطات الحكومية ذات الصلة.
        </p>
      </div>
      <div class="my-16">
        <p class="font-semibold">حماية المعلومات</p>
        <p class="">
          نطبق معايير أمان تقنية وتنظيمية صارمة لضمان حماية بياناتكم من أي مخاطر
          محتملة، مثل الوصول غير المصرح به أو التعديل أو الكشف.
        </p>
      </div>
      <div class="my-4">
        <p class="font-semibold">حقوق المستخدمين</p>
        <p class="">
          · الوصول والتحديث: يمكنكم الوصول إلى معلوماتكم الشخصية وتحديثها من
          خلال إعدادات الحساب.
        </p>
      </div>
      <p class="my-4">
        <span class="font-semibold">· الحذف:</span>
         يمكنكم طلب حذف بياناتكم الشخصية، وسنعمل على تلبية الطلب ما لم تمنعنا
        الالتزامات القانونية أو التنظيمية.
      </p>
      <p class="my-4">
        <span class="font-semibold">· إلغاء الاشتراك: </span>
        يمكنكم إلغاء الاشتراك في الرسائل الترويجية من خلال الرابط المرفق
        بالرسائل.
      </p>
      <div class="my-16">
        <p class="font-semibold">ملفات تعريف الارتباط (Cookies)</p>
        <p class="">
          نستخدم ملفات تعريف الارتباط لتحسين تجربة المستخدم وتحليل كيفية استخدام
          المنصة. يمكنكم التحكم في إعدادات ملفات تعريف الارتباط من خلال إعدادات
          متصفحكم.
        </p>
      </div>
      <div class="my-16">
        <p class="font-semibold">التعديلات على سياسة الخصوصية</p>
        <p class="">
          قد نقوم بتحديث هذه السياسة من وقت لآخر. سيتم إخطاركم بأي تغييرات مهمة
          عبر البريد الإلكتروني أو من خلال إشعار على المنصة.
        </p>
      </div>
      <div class="my-16">
        <p class="font-semibold">الاتصال بنا</p>
        <p class="">
          إذا كان لديكم أي استفسارات أو مخاوف حول سياسة الخصوصية، يمكنكم التواصل
          معنا عبر:
        </p>
        <p class="">
          <span class="font-semibold">· البريد الإلكتروني:</span>
          cashib@gmail.com
        </p>
        <p class="">
          <span class="font-semibold">· الهاتف: </span>
          +996 34922525
        </p>
      </div>
      <p class="font-semibold my-16">
        نشكركم على استخدام منصة كاشيب، ونتطلع إلى تقديم أفضل الخدمات لكم.
      </p>
    </v-container>
  </div>
</template>
  
  <script>
export default {
  name: "Privacy And Policy View",
  components: {},
  data: () => ({}),
};
</script>
  
  <style scoped lang="scss">
.privacy {
  &__header {
    background: #f1f1f1;
    height: 18.75rem;
    display: flex;
    align-items: flex-end;
    padding-bottom: 4.625rem;
    background-image: url("@/assets/images/landing-page/heroo-bg.png");
    background-repeat: no-repeat;
    &__title {
      font-size: 3.125rem !important;
      font-weight: 700;
      margin-bottom: 0;
      line-height: 3.942rem;
      color: $black;
    }
  }
  p {
    line-height: 2.5rem;
    margin-bottom: 0;
  }
}
</style>