

<template>
  <v-app class="app">
    <v-container
      v-if="
        [
          'register',
          'login',
          'forget-password',
          'otp',
          'form-succeed',
          'reset-password',
          '',
        ].includes($route.name)
      "
      class="pt-8 pb-2"
    >
      <router-link :to="{name:'home'}">
        <img width="140px" src="@/assets/images/logo-blue.svg" />
      </router-link>
    </v-container>
    <navbar-component
      v-if="
        ![
          'register',
          'login',
          'forget-password',
          'otp',
          'form-succeed',
          'reset-password',
          '',
        ].includes($route.name)
      "
    />
    <v-container
      fluid
      :class="
        ['home', 'privacy-policy'].includes($route.name)
          ? 'wrapped-container'
          : 'no-wrapped-container'
      "
    >
      <sidebar-component v-if="isLoggedIn" />
      <router-view></router-view>
    </v-container>
    <footer-component v-if="!footerHiddenView.includes($route.name)" />
  </v-app>
</template>

<script>
import NavbarComponent from "@/common/components/Layout/NavbarComponent.vue";
import SidebarComponent from "@/common/components/Layout/SidebarComponent.vue";
import FooterComponent from "@/common/components/Layout/FooterComponent.vue";
export default {
  name: "App",
  components: { NavbarComponent, FooterComponent, SidebarComponent },
  data() {
    return {
      footerHiddenView: [
        "login",
        "forget-password",
        "otp",
        "form-succeed",
        "reset-password",
        "register",
        "shipping-requests",
        "new-request",
        "not-found",
        "request-details",
        "service-providers",
        "support",
        "dashboard",
        "terms",
      ],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  watch: {
    $route(to) {
      this.routeName = to.name;
    },
  },
};
</script>

<style lang="scss">
@import "../src/scss/main.scss";
</style>