import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import HomeView from "@/views/HomeView.vue";
import OldHomeView from "@/views/OldHomeView.vue";
import PrivacyView from "@/views/PrivacyView.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";

// Install Vue Router
Vue.use(VueRouter);

// Override push method to handle NavigationDuplicated error
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") {
      throw err;
    }
  });
};

// Override replace method to handle NavigationDuplicated error
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") {
      throw err;
    }
  });
};

// Reusable beforeEnter guard for redirecting if logged in
const ifNotLoggedInRedirect = (to, from, next) => {
  if (store.state.isLoggedIn) {
    next({ name: "shipping-requests" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "*",
    name: "not-found",
    component: () => import("@/views/NotFoundView.vue"),
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () => import("@/views/ContactUsView.vue"),
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: ifNotLoggedInRedirect,
  },
  {
    path: "/oldHome",
    name: "oldHome",
    component: OldHomeView,
    beforeEnter: ifNotLoggedInRedirect,
  },
  {
    path:"/privacy-policy",
    name: "privacy-policy",
    component: PrivacyView,
    beforeEnter: ifNotLoggedInRedirect,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    beforeEnter: ifNotLoggedInRedirect,
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () => import("@/views/ForgetPasswordView.vue"),
    beforeEnter: ifNotLoggedInRedirect,
  },
  {
    path: "/otp",
    name: "otp",
    component: () => import("@/views/OtpView.vue"),
    beforeEnter: ifNotLoggedInRedirect,
  },
  {
    path: "/form-succeed",
    name: "form-succeed",
    component: () => import("@/views/FormSucceedView.vue"),
    beforeEnter: ifNotLoggedInRedirect,
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/views/ResetPasswordView.vue"),
    beforeEnter: ifNotLoggedInRedirect,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    beforeEnter: ifNotLoggedInRedirect,
  },
  {
    path: "/shipping-requests",
    name: "shipping-requests",
    component: () => import("@/views/ShippingRequestsView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/service-providers",
    name: "service-providers",
    component: () => import("@/views/ServiceProvidersView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/new-request",
    name: "new-request",
    component: () => import("@/views/NewRequestView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/request-details",
    name: "request-details",
    component: () => import("@/views/RequestDetailsView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/DashboardView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/support",
    name: "support",
    component: () => import("@/views/SupportView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/TermsView.vue"),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

// Global beforeEach guard for authentication
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.isLoggedIn) {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
