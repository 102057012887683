<template>
  <div class="innovative-solutions">
    <v-container class="py-0">
      <!-- Header Section -->
      <div class="text-center">
        <h4 class="platform-title">حلول رقمية مبتكرة</h4>
        <h2 class="platform-heading">منصة مبنية على أحدث التقنيات</h2>
        <p class="platform-description">
          تعتمد منصة كاشين على أحدث الابتكارات التقنية لتحسين عمليات الشحن. من الذكاء الاصطناعي لتحليل البيانات إلى معالجة البيانات الضخمة ورقمنة المستندات، نسعى لجعل عمليات الشحن أكثر سرعة وشفافية ودقة.
        </p>
      </div>

      <!-- Card Section -->
      <v-row justify="center" class="features-row">
        <v-col
          v-for="(feature, index) in features"
          :key="index"
          cols="12"
          md="4"
          sm="6"
        >
          <v-card class="feature-card" outlined>
            <div class="feature-icon">
            <img :src="feature.icon" > 
            </div>
            <h3 class="feature-title">{{ feature.title }}</h3>
            <p class="feature-description">{{ feature.description }}</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "InnovativeSolutions",
  data() {
    return {
      features: [
         {
          icon: require("../../assets/images/landing-page/InnovativeSolutions1.png"),
          title: "الذكاء الاصطناعي لتصنيف الشحنات",
          description:
            "تطوّر كاشيب خوارزميات ذكاء اصطناعي لتحليل وفهم بيانات الشحنة بدقة، وتصنيفها وفقاً لرموز النظام المنسّق (HS) لتحديد الفئة الصحيحة تلقائياً. هذه التكنولوجيا تقلّل الأخطاء اليدوية وتسرّع إجراءات فسح الشحنة،",
        },
        {
          icon: require("../../assets/images/landing-page/InnovativeSolutions2.png"),
          title: "رقمنة الوثائق",
          description:
            "في كاشيب، ندرك أن الوثائق الورقية تعيق سرعة العمليات اللوجستية. لذا، نطوّر نظام رقمنة متكاملاً يحوّل جميع المستندات إلى صيغة رقمية موحدة، مما يتيح تبادلها ومشاركتها بسهولة",
        },
         {
          icon: require("../../assets/images/landing-page/InnovativeSolutions3.png"),
          title: "معالجة البيانات الضخمة",
          description:
            "تعتمد كاشيب على تقنيات معالجة البيانات الضخمة لتحليل كميات كبيرة من بيانات الشحنات في الوقت الفعلي. يتيح هذا التحليل فهم حالة الشحنة وموقعها بدقة، وتحسين القدرة على تتبعها",
        },
       
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.innovative-solutions {
  background-color: #f3f3f3;
  margin-top: 5.375rem;

  .platform-title {
    color: #3064c1;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 400;
    padding-top: 3.5rem;
    margin-bottom: 2rem;
  }

  .platform-heading {
    color: #0f172a;
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 1.25rem;
    margin-bottom: 1.5rem;
  }

  .platform-description {
    color: #0f172a;
    font-size: 1.5rem;
    line-height: 2.479rem;
    margin-bottom: 3.5rem;
  }

  .features-row {
    padding-bottom: 4.813rem;

    .feature-card {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 10px;
      text-align: center;
      padding: 20px;
      transition: all 0.3s ease;
      min-height: 23.063rem;

      &:hover {
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
        transform: translateY(-5px);
      }

      .feature-icon {
        margin-bottom: 1.375rem;
        margin-top: 2.125rem;
       img {
        margin: auto;
       }
      }

      .feature-title {
        font-size: 1.25rem;
        font-weight: 700;
        color: #000B33;
        line-height: 2rem;
        margin-bottom: 1.5rem;
      }

      .feature-description {
        color: #000B33;
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 400;
        margin-bottom: 0rem;
      }
    }
  }
}
</style>
