<template>
  <div class="landing-page">
    <v-container class="pb-0">
      <v-row align="center" justify="space-between" no-gutters>
        <!-- Text Section -->
        <v-col cols="6" md="6" class="text-section">
          <div class="hero-section__content">
            <h1 class="title">
              كاشيب..
              <br />
              حل متكامل لجميع شحناتك
            </h1>
            <p class="subtitle">
              منصة واحدة تزيل عنك كل التعقيدات، وتمنحك حلاً شاملاً لإدارة جميع
              شحناتك بسهولة وبنقرة واحدة
            </p>
            <div class="button-group">
              <v-btn
                @click="shippingDetailsDialog = true"
                class="primary-btn elevation-0"
              >
                ادخل بيانـات الشحنـة
              </v-btn>
              <v-btn
                @click="openContactExpertDialog()"
                class="secondary-btn elevation-0"
              >
                تواصــل مع خبيــر
              </v-btn>
            </div>
          </div>
        </v-col>
        <!-- Image Section -->
        <v-col cols="6" md="6" class="image-section">
          <img
            src="../../assets/images/landing-page/herooo.png"
            alt="Loading Dock"
            aspect-ratio="1.3"
            class="image-box"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="shippingDetailsDialog"
      width="600"
      overlay-color="#000"
      overlay-opacity="0.6"
    >
      <v-card class="popup-home pa-6 text-center">
        <div class="d-flex align-center justify-between mb-8">
          <p class="popup-home__label mb-0">ادخل بيانـات الشحنـة</p>
          <img
            @click="shippingDetailsDialog = false"
            class="cursor-pointer"
            src="@/assets/images/close.svg"
            alt="close-btn"
          />
        </div>
        <v-form
          class="popup-home__content"
          v-model="billLadingNumberValid"
          ref="billLadingForm"
          lazy-validation
        >
          <v-subheader class="black--text px-0">رقم بوليصة الشحن</v-subheader>

          <v-text-field
            v-model="billLadingNumber"
            outlined
            :rules="[rules.billLadingNumberRule, rules.required]"
          ></v-text-field>
          <div class="popup-home__actions mt-6">
            <v-btn
              @click="sendBillLadingForm()"
              dark
              color="#1B335E"
              class="elevation-0"
              height="45"
              width="235"
              >تفاصيل الشحنة</v-btn
            >
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="contactExpertDialog"
      width="1000"
      overlay-color="#000"
      overlay-opacity="0.6"
    >
      <v-card class="popup-home pa-6 text-center">
        <div class="d-flex align-center justify-between mb-8">
          <p class="popup-home__label mb-0">تواصل مع الخبير</p>
          <img
            @click="closeContactExpertDialog()"
            class="cursor-pointer"
            src="@/assets/images/close.svg"
            alt="close-btn"
          />
        </div>
        <v-form
          class="popup-home__content"
          v-model="contactExpertDialogValid"
          ref="ContactExpertForm"
          lazy-validation
        >
          <v-row>
            <v-col cols="6">
              <v-subheader class="black--text px-0">الاسم كاملاً</v-subheader>
              <v-text-field
                v-model="name"
                outlined
                :rules="[rules.name, rules.required]"
              ></v-text-field
            ></v-col>
            <v-col cols="6">
              <v-subheader class="black--text px-0">رقم الهاتف</v-subheader>
              <div class="mobile-phone-field">
                <div class="mobile-phone-field__details">
                  <img src="@/assets/images/ksa-flag.svg" alt="KSA Flag" />
                  <span dir="ltr" class="mt-1.5"> +966 </span>
                  <img
                    src="@/assets/images/vertical-seperator.svg"
                    alt="KSA Flag"
                  />
                </div>
                <v-text-field
                  maxlength="9"
                  v-model="mobile"
                  :rules="[rules.required, rules.numbers, rules.saudiNumber]"
                  dense
                  outlined
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <div>
            <v-subheader class="black--text px-0"
              >البريد الالكتروني</v-subheader
            >
            <v-text-field
              v-model="email"
              outlined
              :rules="[rules.required, rules.email]"
            ></v-text-field>
          </div>
          <div>
            <v-subheader class="black--text px-0">الرسالة</v-subheader>
            <v-textarea
              v-model="msg"
              outlined
              required
              auto-grow
              rows="2"
            ></v-textarea>
          </div>
          <div class="popup-home__actions mt-6">
            <v-btn
              @click="sendContactExpertForm()"
              dark
              color="#1B335E"
              class="elevation-0"
              height="45"
              width="235"
              >ارسال</v-btn
            >
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="thanksDialog"
      width="600"
      overlay-color="#000"
      overlay-opacity="0.6"
    >
      <v-card class="pa-6 text-center pt-15">
        <p class="text-3xl mb-5 pt-8">شكراً لتواصلك معنا  </p>
        <p class="text-xl mb-4">
          لقد استلمنا طلبك، وسيتم التواصل معك من قبل فريقنا قريباً. نحن نقدّر
          اهتمامك ونسعد بخدمتك.
        </p>
        <div class="d-flex align-center justify-center">
          <v-btn
            @click="thanksDialog = false"
            dark
            color="#1B335E"
            class="elevation-0"
            height="45"
            width="235"
            >اغلاق</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shippingDetailsDialog: false,
      contactExpertDialog: false,
      thanksDialog: false,
      billLadingNumberValid: false,
      contactExpertDialogValid: false,
      billLadingNumber: "",
      name: null,
      mobile: null,
      email: null,
      msg: null,
      rules: {
        required: (value) =>
          !!value || [true, false].includes(value) || "هذا الحقل إلزامي",
        email: (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "البريد الالكتروني  غير صحيح",
        name: (value) =>
          /^[A-Za-z\u0600-\u06FF\s]+$/.test(value) ||
          "يجب أن يحتوي الاسم على أحرف أبجدية فقط",
        numbers: (value) =>
          !value ||
          (value && /^\d+$/.test(value)) ||
          "يجب أن يحتوي علي أرقام فقط",
        saudiNumber: (value) =>
          !value ||
          (value && /^5\d{8}$/.test(value)) ||
          "يجب أن يكون رقم الجوال سعودياً ويبدأ بـ 5 ويحتوي على 9 أرقام",
        billLadingNumberRule: (value) =>
          !value ||
          /^[0-9]{11}$/.test(value) ||
          "يجب أن يحتوي علي 11 رقمًا فقط",
      },
    };
  },
  methods: {
    openContactExpertDialog() {
      this.contactExpertDialog = true;
    },
    closeContactExpertDialog() {
      this.contactExpertDialog = false;
    },
    validateBillLadingForm() {
      this.$refs.billLadingForm.validate();
    },
    validateContactExpertForm() {
      this.$refs.ContactExpertForm.validate();
    },
    async sendBillLadingForm() {
      await this.validateBillLadingForm();
    },
    async sendContactExpertForm() {
      await this.validateContactExpertForm();
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-page {
  position: relative;
  overflow: hidden;
  background: #f1f1f1;
  display: flex;
  align-items: flex-end;
  height: calc(100vh - 84px);
  max-height: 600px;
  .container {
    background-image: url("../../assets/images/landing-page/heroo-bg.png");
    background-repeat: no-repeat;
    background-position: top;
    background-position: 0px top;
    background-size: calc(100% - 6%) auto;
  }

  .text-section {
    position: relative;
    z-index: 2;
    text-align: start;

    .title {
      font-size: 3.125rem !important;
      font-weight: 700;
      margin-bottom: 1.5rem;
      line-height: 3.942rem;
      color: $black;
      font-family: inherit !important;
    }
    .subtitle {
      font-size: 1.5rem;
      color: #111827;
      margin-bottom: 2.063rem;
      line-height: 2.5rem;
      font-weight: 400;
    }
  }

  .image-section {
    justify-content: start; /* Align image to the start for RTL */
    .image-box {
      border-radius: 0.5rem;
    }
  }

  .button-group {
    display: flex;
    align-items: center;
    gap: 1.781rem;
    .primary-btn {
      background-color: #c53c1c;
      color: white;
      width: 13.625rem;
      height: 3.25rem;
      border-radius: 0.625rem;
      font-weight: 700;
      font-size: 1rem;
    }
    .secondary-btn {
      background: #dce6ed;
      color: #003366;
      font-weight: 400;
      width: 13.625rem;
      height: 3.25rem;
      border-radius: 0.625rem;
      transition: background-color 0.6s ease, color 0.6s ease;
      font-weight: 700;
      font-size: 1rem;
      &:hover {
        background-color: #f1f1f1 !important;
        color: #000000 !important; /* Retains the default color without change */
      }
    }
  }
}
</style>
